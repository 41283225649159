import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router'
import { useQuery, gql, useMutation, ApolloClient } from '@apollo/client';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// Components

import Page from '../../../templates/Page'

// Ducks 

import useQueryParams from '../hooks/useQueryParams';

import { normalizePath } from '../utils'

const GET_ITEM = gql`
    query GetItem($path: String!) {
        item(path: $path) {
            id
            path
            key
            title
            description
        }
    }
`;

const ADD_ITEM = gql`
	mutation AddItem($path: String!, $key: String!, $title: String!, $description: String, $folder: Boolean!) {
		createItem(path: $path, key: $key, title: $title, description: $description, folder: $folder) {
	  		id
		}
  	}
`

const useStyles = makeStyles(theme => ({
        paper : {
            padding: theme.spacing(4),
            minHeight: '320px',
            alignItems: 'center',
            justifyContent : 'center',
            textAlign: 'center'
        
        },
        header : {
            marginBottom : theme.spacing(4)
        },
        footer : {
             marginTop: theme.spacing(2),
             textAlign: 'right'
        }
    })
);


const Create = ({ request, onSubmit, onCancel, onChange }) => {
    const classes = useStyles();
    return (
        <Paper className={ classes.paper }>
                            
            <Typography variant="h6" className={ classes.header }>
                This item does not exists - create it?
            </Typography>

            <TextField variant="outlined" margin="normal" label="Path" value={ request.path } onChange={ e => onChange({ ...request, path : e.target.value }) } fullWidth disabled></TextField>
            <TextField variant="outlined" margin="normal" label="Key" value={ request.key } onChange={ e => onChange({ ...request, key : e.target.value }) }fullWidth disabled></TextField>
            <TextField variant="outlined" margin="normal" label="Title" value={ request.title } onChange={ e => onChange({ ...request, title : e.target.value }) }fullWidth></TextField>
            <TextField variant="outlined" margin="normal" label="Description" value={ request.description } onChange={ e => onChange({ ...request, description : e.target.value }) } rows={ 4 } multiline fullWidth></TextField>

            <div className={ classes.footer}>
                <Button variant="contained" onClick={ onCancel }>Cancel</Button>
                <Button variant="contained" color="primary" onClick={ onSubmit }>Create</Button>
            </div>
        </Paper>
    )
}



const Lookup = () => {
    const { path, key, title, description } = useQueryParams();
    const history = useHistory();
    
    const normalizedPath = normalizePath(path);

    const { loading, data } = useQuery(GET_ITEM, { variables : { path : normalizedPath + key }});
    const [addItem] = useMutation(ADD_ITEM);

    const [request, setRequest] = useState({ path : normalizedPath, key, title, description, folder : false })

    const handleCreate = async () => {
        await addItem({ variables: request });
        
        history.push(`/mapping/items?path=${ normalizedPath }&current=${ normalizedPath + key }`)
    }

    if (loading) {
        return <Page title="Mapping" busy></Page>
    }
    else {
        return (
            <Page title="Mapping">
                {
                    data.item &&
                    <Redirect to={ `/mapping/items?path=${ normalizedPath }&current=${ normalizedPath + key}` } />
                }

                {
                    !data.item &&
                    <Container maxWidth="sm">
                        { request && <Create request={ request } onCancel={ () => history.goBack() } onChange={ value => setRequest(value) } onSubmit={ handleCreate} /> }
                    </Container>
                }
            </Page>
        )
    }
}

export default Lookup;
