// Templates

import IFramedApp from '../../templates/IFramedApp'

const App = () => {
    return (
        <IFramedApp title={ 'Legacy Fantasy Sport' } baseUrl={ process.env.REACT_APP_SEASON_LEGACY_BASE_URL } />
    )
}

export default App;