import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

// Material UI
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

// Ducks
import { getGeneralErrors, dismissGeneralError } from "../ducks/ui";

const ErrorHandler = () => {
	const dispatch = useDispatch();

    const errors = useSelector(getGeneralErrors);

    let error = errors.length > 0 ? errors[errors.length - 1] : null;

	const handleDismiss = () => {
		dispatch(dismissGeneralError())
	}

    if (!error)
        return null;

    return (
        <Paper elevation={0} variant="outlined" square>
            <Container>
                <Box p={ 1 }>
                    <div style={{ display : 'flex', alignItems: 'center'}}>
                        <div style={{ flexGrow : 1 }}>
                            { error }
                        </div>
                        <div>
                            <Button color="primary" onClick={ handleDismiss }>Dismiss</Button>
                        </div>
                    </div>
                </Box>
                
            </Container>
        </Paper>

    );
	
}

export default ErrorHandler;
