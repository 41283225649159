// Libraries

import React, { useState } from 'react';
import { API } from 'aws-amplify'
import { useDispatch } from 'react-redux';
import { useApolloClient, useMutation } from '@apollo/client';

// Material UI

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import { parsePath } from '../utils'

import { GET_ITEM, ADD_LINK } from '../queries';


const useLazyQuery = (query) => {
	const client = useApolloClient();

	return React.useCallback((variables) => {
		return client.query({ query: query, variables: variables })
	}, [client])
}


const CreateLinkDialog = ({ open, item, title,  onClose }) => {
	const [fullPath, setFullPath] = useState('');
	const [error, setError] = useState('');
	const getItem = useLazyQuery(GET_ITEM);
	const [addLink] = useMutation(ADD_LINK);
	
	
	const handleCreate = async () => {
		const { path, key } = parsePath(fullPath);

    	var res = await getItem({ path : path + '/' + key });
    	if (res.data.item) {
			try {
				await addLink({ variables : { source : parseInt(item.id), target : parseInt(res.data.item.id) } });
				setError('');
				onClose(true);
			}
			catch (e) {
				setError(e.message)
			}
			
		}
		else {
			setError('Invalid path/key.')
		}
	}

    return (
        <Dialog open={open} onClose={ () => onClose(false) } aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{ title }</DialogTitle>
            <DialogContent>
                <DialogContentText>
                  To create a link manually enter the target path of the object to link this item to below.
              </DialogContentText>
              <TextField value={ fullPath } error={ !!error } helperText={ error } autoFocus margin="dense" id="name" label="Target path" type="name" fullWidth onChange={ e => setFullPath(e.target.value) }  />
            </DialogContent>
            <DialogActions>
              <Button onClick={ () => onClose(false) } color="primary">Cancel</Button>
              <Button onClick={ handleCreate } disabled={ fullPath.length < 1 } color="primary">Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateLinkDialog;