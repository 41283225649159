import React, { useState } from 'react';

import { useMutation, gql } from '@apollo/client';

// Material UI

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from  '@material-ui/core/Switch';
import FormControlLabel from  '@material-ui/core/FormControlLabel';

const initialItem = {
	 key : '', 
	 title : '', 
	 description : '', 
	 folder : false
}

const ADD_ITEM = gql`
	mutation AddItem($path: String!, $key: String!, $title: String!, $folder: Boolean!) {
		createItem(path: $path, key: $key, title: $title, folder: $folder) {
	  		id
		}
  	}
`

const NewItemDialog = ({ path, title, open, onClose }) => {
	const [addItem, { data, error }] = useMutation(ADD_ITEM);

	const [item, setItem] = useState({ ...initialItem });
	const [validationErrors, setSetValidationErrors] = useState();

	const handleConfirm = async () => {
		try {
			await addItem({ variables: { path : path || '/', ...item } });
			onClose();
			setItem({ ...initialItem });
			setSetValidationErrors(null);
		}
		catch (e) {
			if (e.graphQLErrors) {
				e.graphQLErrors.forEach(err => {
					if (err.extensions.code == 'BAD_USER_INPUT') {
						setSetValidationErrors(err.extensions.validationErrors);
					}
				})
			}
			else
				throw e;
		}
	}


	const handleCancel = () => {
		onClose();
		setItem({ ...initialItem });
		setSetValidationErrors(null);
	}

    return (
        <Dialog open={open} onClose={ handleCancel } aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{ title || 'New Item' }</DialogTitle>
            <DialogContent>
    
              <TextField 
			  		value={ item.key } 
					error={ validationErrors && validationErrors.key } 
				  	variant="outlined" autoFocus margin="normal" id="key" label="Key" fullWidth onChange={ e => setItem({ ...item, key : e.target.value })} 
				  	helperText={ validationErrors && validationErrors.key}
				 />
              
			  <TextField value={ item.title } 
			 		error={ validationErrors && validationErrors.title} 
			 
			 		variant="outlined" margin="normal" id="name" label="Title" type="email"
			 
			 		helperText={ validationErrors && validationErrors.title} 
			 		fullWidth onChange={ e => setItem({ ...item, title : e.target.value })}  />


              <TextField value={ item.description } variant="outlined" margin="normal" id="name" label="Description" multiline rows={3} fullWidth  onChange={ e => setItem({ ...item, description : e.target.value })} />

              <FormControlLabel 
                control={ <Switch checked={ item.folder } onChange={ e => setItem({ ...item, folder : e.target.checked }) } name="checkedB" color="primary" /> } 
                label="Folder?" />
              
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel } color="primary">Cancel</Button>
              <Button onClick={ handleConfirm } color="primary">Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default NewItemDialog;