import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory  } from 'react-router'

// Material UI

import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';

// Material UI Icons

import AddIcon from '@material-ui/icons/Add';

// Components

import Page from '../../../templates/Page'
import NewItemDialog from '../dialogs/NewItemDialog'

// Ducks 

import useQueryParams from '../hooks/useQueryParams';

import ItemTree from '../containers/ItemTree';
import ItemDetails from '../containers/ItemDetails';
import { useQuery, gql } from '@apollo/client';


const ITEMS = gql`
    query GetItems($path : String, $query : String, $limit : Int, $after : String, $before : String) {
        items(path : $path, query : $query, limit : $limit, after : $after, before : $before) {
            totalCount
            edges {
                node {
                    path
                    key
                    title
                    folder
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;


const Mappings = () => {
    const { path, current, after, before } = useQueryParams();
    const history = useHistory();

    const [showNewItem, setShowNewItem] = useState(false);
    const [query, setQuery] = useState('')

    const { data, previousData, refetch } = useQuery(ITEMS, { variables : { path, query, limit : 10, after, before } });

    const handleNewItemCreated = () => {
        setShowNewItem(false);
        refetch ();
    }

    useEffect(() => {
        setQuery('')
    }, [path]);

    const { items } = (data ||previousData) || { items : { pageInfo : {} } }
    const { edges } = items || { edges : [] }
    
    var nodes = edges ? edges.map(e => e.node) : [];

    
    const handleDeleted = () => {
        history.push(`/mapping/items?path=${ path }`);
        refetch();
    }

    const handleChangeQuery = (e) => {
        setQuery(e.target.value);
    }

    const handleRefresh = (e) => {
        refetch();
    }

    return (
        <Page title="Items">
            <Container>
                <Grid container spacing={3}>
                    <Grid xs={ 12 } md={ 5} item>
                        
                          { items &&  <ItemTree items={nodes } path={ path } 
                          query={ query }
                          onChangeQuery={ handleChangeQuery }
                          after={ after }
                          before={ before }
                          onRefresh={ handleRefresh }
                            startCursor={ items && items.pageInfo.startCursor}
                            endCursor={ items && items.pageInfo.endCursor}
                            showPrevious={ items && items.pageInfo.hasPreviousPage } showNext={ items && items.pageInfo.hasNextPage }
                            
                            
                            />}
                        
                    </Grid>
                    <Grid xs={12} md={ 7 } item>
                         { current && <ItemDetails path={ current } onDeleted={ handleDeleted } /> } 
                    </Grid>
                </Grid>
            </Container>

            <NewItemDialog open={ showNewItem } path={ path } title={ `New item in ${ path }`} onClose={ handleNewItemCreated } />

            <Fab color="secondary" onClick={ () => setShowNewItem(true) }  style={{ position: 'fixed', bottom: '24px', right : '24px' } }>
                <AddIcon/>
            </Fab>
        </Page>
    )
}

export default Mappings;
