import { useLocation } from "react-router";


const useQueryParams = () => {
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    
    let params = Array.from(searchParams.keys()).reduce((acc, k) => {
        acc[k] = searchParams.get(k);
        return acc;
    }, {})

    return params;
}


export default useQueryParams;