import React from 'react'

// Material UI

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

const ConfirmationDialog = ({ open, title, text, onConfirm, onCancel }) => {
    return (
        <Dialog open={open} onClose={ onCancel } aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{ title }</DialogTitle>
            <DialogContent>
                <DialogContentText>{ text }</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={ onCancel } color="primary">Cancel</Button>
                <Button onClick={ onConfirm } color="primary">Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog;