import { Switch, Route  } from "react-router-dom";

import Alerts from './pages/Alerts'
import Alert from './pages/Alert'

function App() {
	return (
		<Switch>
			<Route path="/alerts/:id" component={ Alert } />
            <Route path="/alerts" component={ Alerts } />
		</Switch>
	);
}

export default App;
