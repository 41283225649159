import React from 'react';
import clsx from 'clsx'
import { useQuery } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu'
import ErrorIcon from '@material-ui/icons/Error';

import { Auth } from 'aws-amplify'

import PageMenu from '../components/PageMenu'
import QuickNav from '../components/QuickNav'

import { useDispatch, useSelector } from 'react-redux';

import { toggleMenu } from '../ducks/ui';
import { useHistory } from 'react-router';

import { Badge, CircularProgress, Typography, useTheme } from '@material-ui/core';
import PageHeader from '../components/PageHeader';

import ErrorHandler from '../containers/ErrorHandler';
import gql from 'graphql-tag';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		inset: 0,
		display: 'flex',
		flexDirection: 'column'
  	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1
  	},
	page: {
		position: 'absolute',
		inset: 0,
		display: 'flex',
		flexDirection : 'column',
		
	},
	pageMenuOpen: {
		width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
		
  	},

	content: {
		flex: 1,
		position: 'relative',
		overflowX: 'hidden',
		overflowY: 'auto'
		
  	},
	
	  contentPadding : {
		marginTop: theme.spacing(3)
	},


  	menuButton: {
    	marginRight: theme.spacing(2),
  	},
  	title: {
    	flexGrow: 1,
		
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		},
  	},
	offset: theme.mixins.toolbar
}));


const GET_ALERT_METRICS = gql`
	 query GetAlertMetrics {
        alertMetrics {
           error
		   warning
        }
    }
`

export default function Page({ busy, title, children, fullHeight }) {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
	const history = useHistory();
    const doShowMenu = useSelector(state => state.ui.showMenu);
	const { data } = useQuery(GET_ALERT_METRICS, { pollInterval: 60000 });
	const alertCount = data && (data.alertMetrics.error || 0) + (data.alertMetrics.warning || 0)

	const pageClasses = clsx(classes.page, 
        {
          [classes.pageMenuOpen] :doShowMenu

      	}
	)

	const contentClasses = clsx(classes.content, 
        {
          [classes.contentPadding] : !fullHeight

      	}
	)


  const handleShowMenu = () => {
	dispatch(toggleMenu());
  }

  const handleAccountClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    setAnchorEl(null);
    Auth.signOut();
  }

  const clearGeneralError = () => {
	  dispatch(clearGeneralError())
  }

  return (
    <div className={ classes.root }>
		<AppBar className={ classes.appBar } elevation={ 0 } color="white">
			<Toolbar>
				<IconButton color="inherit" edge="start" className={ classes.menuButton } onClick={ handleShowMenu }>
					<MenuIcon/>
				</IconButton>


				
					<Typography variant="h6" className={ classes.title }>Swush Management Console</Typography>
				

					<QuickNav onNavigate={ path => history.push(path) } />



						<IconButton component={ RouterLink } to="/alerts" color="inherit">
							<Badge badgeContent={ alertCount } color="error">
								<ErrorIcon />
							</Badge>
						</IconButton>
				

				<IconButton color="inherit" onClick={ handleAccountClick } edge="end">
					<AccountCircleIcon />
				</IconButton>

			</Toolbar>
		</AppBar>

		<div className={ pageClasses}>
			<div className={ classes.offset } />




				<Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={ Boolean(anchorEl) } onClose={ handleClose }>
					<MenuItem onClick={ handleSignOut}>Sign out</MenuItem>
				</Menu>
			
				<PageHeader title={ title }></PageHeader>

					<ErrorHandler />

			<div className={ contentClasses }>
				

					{ children}
			
			{ busy && 
				<div style={{ position: 'absolute', inset : 0, display: 'flex',alignItems: 'center', justifyContent : 'center' }}>
					<CircularProgress/>
				</div>
				}
			</div>
		</div>

		<PageMenu/>
    </div>
  );
}

