const sitemap = {
    children : [
        {
            
            name : 'System',
            path : '/',
            image : '/img/modules/data-management.svg',
            children : [
                { name : 'Home', path : '/', description : 'Home' },
                { name : 'Alerts', path : '/alerts', description : 'Alerts' },
                { name : 'Logs', path : '/logs', description : 'Logs' }
            ]
        },

        {
            
            name : 'Data Management',
            path : '/mapping',
            image : '/img/modules/data-management.svg',
            children : [
                {
                    name : 'Mapping',
                    children : [
                        { name : 'Dashboard', path : '/mapping', description : 'View and manage mappings' },
                        { name : 'Items', path : '/mapping/items', description : 'View and manage mapped children' }
                    ]
                }
            ]
        },
 
        {
            name : 'Season Fantasy',
            path : '/season',
            image : '/img/modules/legacyfantasy.jpg',
            children : [
                { name : 'Dashboard', path : '/season' },
                { name : 'Alerts', path : '/season/app/v2#/alerts' },
                {
                    name : 'Customers',
                    children : [
                        { name : 'Users', path : '/season/general/Users/Users/list.aspx', description : 'Manage end users' },
                        { name : 'Account Verification Media', path : '/season/Users/UserVerificationMedia.aspx', description : 'View and manage account verification media' },
                    ]
                },
                {
                    name : 'Sport',
                    children : [
                        { name : 'Persons', path : '/season/app#/persons', description : 'Manage persons' },
                        { name : 'Players', path : '/season/general/tournaments/TournamentContracts.aspx', description : 'Manage players in a tournament' },
                        { name : 'Players - Import', path : '/season/general/Tournaments/ImportTournamentElements.aspx', description : 'Import players to a tournament' },
                        { name : 'Player Merger', path : '/season/general/Elements/ElementMerger.aspx', description : 'Import players to a tournament' },
                        { name : 'Matches', path : '/season/general/Matches/Basic/List.aspx', description : 'Manage matches' },
                        { name : 'Match groups', path : '/season/general/matchgroups/list.aspx', description : 'Manage match groups' },
                        { name : 'Assists', path : '/season/general/Games/Assists.aspx', description : 'Manage match groups' },
                        { name : 'Teams', path : '/season/general/teams/list.aspx', description : 'Manage match groups' },
                        { name : 'Tournament teams', path : '/season/general/tournaments/TournamentTeams.aspx', description : 'Manage which teams participate in a tournament' },
                        { name : 'Formations', path : '/season/general/Formations/list.aspx', description : 'Manage formations' },
                        { name : 'Locations', path : '/season/general/administration/locations/list.aspx', description : 'Manage locations' },
                        { name : 'Positions', path : '/season/general/administration/Positions/list.aspx', description : 'Manage positions' },
                        { name : 'Sports', path : '/season/general/administration/Sports/list.aspx', description : 'Manage sports' },
                        { name : 'Tournaments', path : '/season/general/administration/tournaments/list.aspx', description : 'Manage tournaments' }
                    ]
                },
                {
                    name : 'Social',
                    children : [
                        { name : 'Groups export', path : '/season/general/Users/Users/GroupExport.aspx', description : 'Export group results' },
                    ]
                },
                {
                    name : 'Fantasy',
                    children : [
                        { name : 'Fantasy teams', path : '/season/general/Users/Teams/list.aspx', description : 'Manage fantasy teams' },
                        { name : 'Games', path : '/season/general/administration/Games/list.aspx', description : 'Manage games' },
                        { name : 'Turns', path : '/season/general/Turns/List.aspx', description : 'Manage turns' },
                        { name : 'Events', path : '/season/general/administration/eventTypes/list.aspx', description : 'Manage events' },
                        { name : 'Rulesets', path : '/season/general/Rulesets/list.aspx', description : 'Manage rulesets' },
                        { name : 'Ruleset formations', path : '/season/general/Rulesets/formations.aspx', description : 'Manage which formations a ruleset uses' },
                        { name : 'Player values', path : '/season/general/elements/adjustments.aspx', description : 'Manage player values' },
                        { name : 'Import player values', path : '/season/general/Import/Import.aspx', description : 'Import player values' },
                        { name : 'Popularity', path : '/season/general/elements/popularity.aspx', description : 'View player popularity reports' },
                        { name : 'Revenue report', path : '/season/general/reports/salesui.aspx', description : 'View revenue reports' },
                        { name : 'Prize report', path : '/season/general/reports/prizereport.aspx', description : 'View prize reports' },
                    ]
                },
                {
                    name : 'Users & Teams',
                    children : [
                        { name : 'Achievements', path : '/season/general/Achievements/AwardUserAchievements.aspx', description : 'Award user achievements' },
                        { name : 'Mailing list', path : '/season/general/Users/Users/MailingList.aspx', description : 'Extract users for mailing lists' }
                    ]
                },
                {
                    name : 'Client management',
                    children : [
                        { name : 'Clients', path : '/season/general/administration/clients/list.aspx', description : 'Manage clients' },
                        { name : 'Websites', path : '/season/general/administration/websites/list.aspx', description : 'Manage websites and game offerings' },
                        { name : 'Subsites', path : '/season/general/administration/subsite/list.aspx', description : 'Manage websites and game offerings' },
                    ]
                },
                {
                    name : 'Shopping',
                    children : [
                        { name : 'Orders', path : '/season/general/Shopping/Order/Find.aspx', description : 'Manage orders' },
                        { name : 'Products', path : '/season/general/Shopping/Products/Find.aspx', description : 'Manage products' },
                        { name : 'Offerings', path : '/season/app#/tournaments/387/shopping/offerings', description : 'Manage product offerings' },
                        { name : 'Coupons', path : '/season/general/Shopping/coupon/list.aspx', description : 'Manage coupons' },
                        { name : 'Feature levels', path : '/season/general/FeatureSystem/Find.aspx', description : 'Manage feature levels' },
                        { name : 'Freemium model builder', path : '/season/general/Shopping/FeatureLevelAvailabilityBuilder/Standard.aspx', description : 'Build freemium models' },
                        { name : 'Localized products', path : '/season/general/TextContent/SubsiteProductFinder.aspx', description : 'Manage product localization' },
                    ]
                },
                {
                    name : 'Content Management',
                    children : [
                        { name : 'Containers', path : '/season/General/TextContent/Default.aspx', description : 'Manage translation containers' },
                        { name : 'Search', path : '/season/General/TextContent/Search.aspx', description : 'Find translation items' },
                        { name : 'Bulk import', path : '/season/General/TextContent/Import.aspx', description : 'Bulk import translation items' },
                        { name : 'FAQ categories', path : '/season/general/faq/faqcategory.aspx', description : 'Manage FAQ categories' },
                        { name : 'FAQ items', path : '/season/general/faq/faqitem.aspx', description : 'Manage FAQ items' },
                        { name : 'Views', path : '/season/app#/views', description : 'Manage views' }
                    ]
                },
                {
                    name : 'Mailing lists',
                    children : [
                        { name : 'Templates', path : '/season/general/support/MailTemplates.aspx', description : 'Manage mailing list templates' },
                        { name : 'Componser', path : '/season/general/support/MailComposer.aspx', description : 'Mailing list composer' },
                        { name : 'Turn status mail', path : '/season/general/support/TurnStatusMailsSender.aspx', description : 'Turn status mail' }
                    ]
                },
                {
                    name : 'Prizes',
                    children : [
                        { name : 'Bundles', path : '/season/app#/prizes/bundles', description : 'Manage prize bundles' },
                        { name : 'Claims', path : '/season/app#/prizes/claims', description : 'Manage prize claims' }
                    ]
                },
                {
                    name : 'Data Management (Legacy)',
                    children : [
                        { name : 'Datasources', path : '/season/app#/datasources', description : 'Manage datasources' },
                        { name : 'Feeds', path : '/season/app/v2#/feeds', description : 'Manage feeds' },
                        { name : 'Infostrada', path : '/season/app#/dataproviders/infostrada', description : 'Manage infostrada' },
                        { name : 'Infostrada match importer', path : '/season/general/infostrada/matchImporter.aspx', description : 'Infostrada match importer' },
                        { name : 'Infostrada match linker', path : '/season/general/infostrada/MatchLinker.aspx', description : 'Infostrada match linker' },
                        { name : 'Infostrada elements', path : '/season/general/infostrada/element.aspx', description : 'Infostrada elements' },
                        { name : 'Infostrada elements (Swush)', path : '/season/general/infostrada/ElementSwushBased.aspx', description : 'Infostrada elements (Swush)' },
                        { name : 'Formula1.com', path : '/season/app#/dataproviders/formula1', description : 'Formula 1' },
                        { name : 'Formula 1 editor', path : '/season/general/Games/Formula1/List.aspx', description : 'Formula 1 editor' },
                        { name : 'FantasyData.com', path : '/season/app#/dataproviders/nfldatacom', description : 'FantasyData.com' },
                        { name : 'Golf import', path : '/season/general/Games/Golf/Importer.aspx', description : 'Golf import' },
                        { name : 'Golf editor', path : '/season/general/Games/Golf/MatchEditor.aspx', description : 'Golf editor' },
                        { name : 'Handball', path : '/season/general/Games/Handball/MatchSelector.aspx', description : 'Handball' },
                        { name : 'Hockey - Nations', path : '/season/general/Games/Icehockey/MatchSelect.aspx', description : 'Hockey nations' },
                    ]
                },
                { name : 'Picks', path : '/season/picks', description : 'Manage picks games' },
                {
                    name : 'System',
                    children : [
                        { name : 'Jobs', path : '/season/app#/jobs', description : 'Manage jobs' },
                        { name : 'Languages', path : '/season/general/administration/Languages/list.aspx', description : 'Manage languages' },
                        { name : 'DatasourceLink', path : '/season/general/DatasourceLink/Tools.aspx', description : 'DatasourceLink' },
                        { name : 'Administrators', path : '/season/general/administration/administrators/list.aspx', description : 'Manage administrators' },
                        { name : 'Access Groups', path : '/season/general/administration/accessGroups/list.aspx', description : 'Manage access Groups' },
                        { name : 'Cache bust', path : '/season/cachebust', description : 'Cache bust' },
                        { name : 'Force update', path : '/season/forceupdate.aspx', description : 'Force update' },
                        { name : 'Log', path : '/season/general/logs/Log.aspx', description : 'Log' },
                        { name : 'Log out (Legacy)', path : '/season/logout.aspx', description : 'Log out (Legacy)' }
                    ]
                },
            ]
        },
    
        {
            name : 'Sportsbet',
            path : '/sportsbet',
            image : '/img/modules/sportsbet.svg',
            children : [
                {
                    name : 'System',
                    children : [
                        { name : 'Alerts', path : '/sportsbet/system/alerta', description : 'View alerts' },
                        { name : 'FAQ', path : '/sportsbet/system/faq/categories', description : 'Manage FAQs' },
                        { name : 'Jobs', path : '/sportsbet/system/jobs', description : 'Manage jobs' },
                        { name : 'Mail templates', path : '/sportsbet/system/mailtemplates', description : 'Manage mail templates' },
                        { name : 'Mail template partials', path : '/sportsbet/system/mailtemplatepartials', description : 'Manage mail template partials' },
                        { name : 'Media library', path : '/sportsbet/system/media-library', description : 'View media library' },
                        { name : 'Providers', path : '/sportsbet/system/providers', description : 'Manage providers' },
                        { name : 'Reactions', path : '/sportsbet/system/reactions', description : 'Manage reactions' },
                        { name : 'Schemas', path : '/sportsbet/system/schemas', description : 'Manage schemas' },
                        { name : 'Segments', path : '/sportsbet/system/segments', description : 'Manage segments' },
                        { name : 'Tasks', path : '/sportsbet/system/tasks', description : 'Manage tasks' },
                        { name : 'Users', path : '/sportsbet/system/users', description : 'Manage users' },
                        { name : 'Webhooks', path : '/sportsbet/system/webhookhandlers', description : 'Manage webhooks' }
                    ]
                },
                {
                    name : 'Affiliate',
                    children : [
                        { name : 'Clients', path : '/sportsbet/affiliate/affiliateclients', description : 'Affiliate clients' },
                        { name : 'Affiliates', path : '/sportsbet/affiliate/affiliates', description : 'Affiliates' },
                        { name : 'Commission Event Types', path : '/sportsbet/affiliate/commissioneventtypes', description : 'Manager commission event types' },
                        { name : 'Programs', path : '/sportsbet/affiliate/programs', description : 'Manage affiliate programs' }
                    ]
                },
                {
                    name : 'Daily Fantasy',
                    children : [
                        { name : 'Games', path : '/sportsbet/dfs/game', description : 'Manage games' },
                        { name : 'Prize Models', path : '/sportsbet/dfs/prize-models', description : 'Manage daily prize models' },
                        { name : 'Rooms', path : '/sportsbet/dfs/rooms', description : 'Manage rooms' },
                        { name : 'Rulesets', path : '/sportsbet/dfs/rulesets', description : 'Create and manage rulesets' },
                        { name : 'SVG Tester', path : '/sportsbet/dfs/svgtester', description : 'Test svg player generation' },
                        { name : 'Synthetic Event Types', path : '/sportsbet/dfs/syntheticeventtypes', description : 'Create and manage synthetic event types' },
                    ]
                },
                {
                    name : 'Economy',
                    children : [
                        { name : 'Wager Transmissions', path : '/sportsbet/economy/wagertransmissions', description : 'View wager transmissions' },
                        { name : 'Wagers', path : '/sportsbet/economy/wagers', description : 'View wagers' }
                    ]
                },
                {
                    name : 'Social',
                    children : [
                        { name : 'Groups', path : '/sportsbet/social/groups', description : 'View and mange groups' }
                    ]
                },
                {
                    name : 'Sport',
                    children : [
                        { name : 'Event Types', path : '/sportsbet/sport/eventtypes', description : 'View and manage different types of events' },
                        { name : 'Leagues', path : '/sportsbet/sport/leagues', description : 'View and manage leagues' },
                        { name : 'Matches', path : '/sportsbet/sport/matches', description : 'View and manage matches' },
                        { name : 'Positions', path : '/sportsbet/sport/positions', description : 'View and manage positions' },
                        { name : 'Sports', path : '/sportsbet/sport/sports', description : 'View and manage sports' },
                        { name : 'Tournaments', path : '/sportsbet/sport/tournaments', description : 'View and manage tournaments' }
                    ]
                }
                
            ]
        },

        {
            name : 'SAM',
            path : '/sam',
            image : '/img/modules/sam.svg',
            children : [
                {
                    name : 'Swush',
                    children : [
                        { name : 'Leaderboards', path : '/sam/swush/leaderboards', description : 'Manage leaderboards' },
                        { name : 'Payment requests', path : '/sam/swush/payment-requests', description : 'Manage payment requests' },
                        { name : 'Users', path : '/sam/swush/users', description : 'Manage users' },
                        { name : 'Validation requests', path : '/sam/swush/validation-requests', description : 'Manage validation requests' }
                    ]
                },
                {
                    name : 'System',
                    children : [
                        { name : 'FAQ', path : '/sam/swush/faq', description : 'Manage FAQs' },
                        { name : 'Segments', path : '/sam/swush/segments', description : 'Manage segments' }
                    ]
                }
            ]
        }
    ]
}

export default sitemap;