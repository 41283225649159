import { makeStyles, useTheme } from '@material-ui/core/styles';

import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import CheckIcon from '@material-ui/icons/Check';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
    root : {
        display: 'flex',
        alignItems : 'center',
    },
    icon : {
        fontSize: 0,
        marginRight: theme.spacing(1)
    },
    error : {
        color : theme.palette.status.danger
    }
}))



const Icon = ({ status }) => {
    const theme = useTheme();

    switch (status) {
        case 'NEW':
            return <NewReleasesIcon style={{ color: theme.palette.status.info }} />
        case 'DISMISSED':
            return <ClearIcon style={{ color: theme.palette.status.danger }} />
        case 'RESOLVED':
            return <CheckIcon style={{ color: theme.palette.status.success }}/>
        case 'IGNORED':
            return <VisibilityOffIcon/>
    }
}


const AlertStatus = ({ status }) => {
    const classes = useStyles();

    if (!status)
        return null;

    return (
        <div className={ classes.root }>
            <div className={ classes.icon }>
                <Icon status={ status } />
            </div> 
            { status }
        </div>
    )


}

export default AlertStatus;