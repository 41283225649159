import { Switch, Route } from 'react-router-dom'

import TemporaryDrawer from '../../components/PageMenu'
import PageHeader from '../../components/PageHeader'
import Page from '../../templates/Page'

const Main = () =>{
    return (
        <Page>
            
            <h1>Ok</h1>
        </Page>
    )
}

const Routes = () => {
    return (
        <Switch>
            <Route path="/import" component={ Main }/>
        </Switch>
    )
}


const App = () => {
    return <Routes />
}



export default App;