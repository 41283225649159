import { gql } from '@apollo/client';

export const GET_ALERTS = gql`
    query GetAlerts($status : AlertStatus, $limit: Int!, $after : String, $before : String ) {
        alerts(status : $status, limit: $limit, after: $after, before: $before) {
            totalCount
            edges {
                node {
                    id
                    group
                    title
                    instanceCount
                    status
                    type
                    created
                    lastSeen
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

export const GET_ALERT = gql`
    query GetAlert($id: ID!, $after: String, $before: String) {
        alert(id : $id) {
            id
            title
            instanceCount
            status
            type
            created
            lastSeen
            instances(limit: 1, after: $after, before: $before) {
                edges {
                    node {
                        id
                        message
                        details
                        created
                    }
                },
                pageInfo 
                {
                    endCursor
                    startCursor
                    hasNextPage
                    hasPreviousPage
                }
                
            }
        }
    }
`;


export const RESOLVE_ALERT = gql`
    mutation ResolveAlert($id: ID!) {
        resolveAlert(id : $id) {
            id
        }
    }
`;

export const REOPEN_ALERT = gql`
    mutation ReopenAlert($id: ID!) {
        reopenAlert(id : $id) {
            id
        }
    }
`;

export const DISMISS_ALERT = gql`
    mutation DismissAlert($id: ID!, $time: Int) {
        dismissAlert(id : $id, time: $time) {
            id
        }
    }
`;


export const IGNORE_ALERT = gql`
    mutation IgnoreAlert($id: ID!) {
        ignoreAlert(id : $id) {
            id
        }
    }
`;