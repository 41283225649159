






export const flatten = (children, getChildren, level, parent) => Array.prototype.concat.apply(
    children.map((x, idx) => ({ ...x, id : parent ? parent + '.' + idx : idx + '', level: level || 1, parent: parent || null })), 

    children.map((x, idx) => flatten(getChildren(x) || [], getChildren, (level || 1) + 1, parent ? parent + '.' + idx : idx + ''))
);