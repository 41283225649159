/* eslint-disable no-use-before-define */
import React, { useMemo, useRef } from 'react';
import useHotkeys from 'react-use-hotkeys'

// Material UI

import { fade, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

// Icons 

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

// Data

import sitemap from '../sitemap'
import { flatten } from '../utils';
import { Avatar } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
	inline : { display: 'inline' },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
	},

    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
		


		
      },
	  primaryItem : {
		  display: 'flex',
		  alignItems: 'center'
	  },

      inputRoot: {
        color: 'inherit',
		width: '100%'
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 0),

        paddingLeft: `calc(1em + ${ theme.spacing(4) }px)`,
        
		transition: theme.transitions.create('width'),

		width: '100%'
		
      }
}));

const filterOptions = createFilterOptions({
	stringify: option => option.title + ' ' + option.description,
});




export default function ComboBox({ onNavigate }) {
	const classes = useStyles();
	const inputRef = useRef();

	useHotkeys('Control+ArrowUp', () => {
		if (inputRef.current) {
			inputRef.current.focus()
			inputRef.current.select();
		}
	})

	const options = useMemo(() => {
		const flat = flatten(sitemap.children, child => child.children);
		return flat.filter(i => !i.children || i.children.length == 0).map(item => {


			const parts = item.parent ? item.parent.split('.') : [];

			let lineage = [];
			let path = null;

			for(let i = 0; i < parts.length; i++) {
				if (!path)
					path = parts[i];
				else 
					path += '.' + parts[i];

				lineage.push(flat.find(f => f.id == path));
			}
			

			const group = lineage.length > 0 ?
				lineage.reduce((acc, l) => {
					if (acc)
						return acc + ' > ' + l.name;
					else
						acc = l.name
					return acc;
				}, null) : '';



			return {
				module : 'MODULE NAME', 
				group : group, 
				image : lineage[0].image,
				name : item.name, 
				title : item.name, 
				description: item.description, 
				path : item.path  
			}
		});
  }, [sitemap]);


  const handleChange = (e, newValue) => {
    onNavigate(newValue.path);
  }

  const handleOnFocus = (e) => {
		e.target.select();

  }

  return (
    <Autocomplete
      id="combo-box-demo"
      options={ options }
      getOptionLabel={(option) => option.label}
	  filterOptions={ filterOptions }
      style={{ width: 480 }}
      onChange={ handleChange }
	  groupBy={(option) => option.group}
      renderOption={(option) => (
		  	<>
			  <ListItemAvatar>
				  <Avatar src={ option.image }>
				  
				  </Avatar>
			  </ListItemAvatar>
			<ListItemText primary={ option.title  } secondary={ option.description }>
			</ListItemText>
			</>

      )}
      renderInput={(params) => (
		<div className={ classes.search } ref={ params.InputProps.ref}>
        	<div className={ classes.searchIcon }>
            	<SearchIcon />
        	</div>
      		<InputBase placeholder="Search…" {...params.inputProps} onFocus={ handleOnFocus} inputRef={ inputRef } classes={{ root: classes.inputRoot, input: classes.inputInput }} />
  		</div>
	  )}
    />
  );
}
