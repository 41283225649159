import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client';

// Material UI
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// Material UI Icons

import { LinearProgress, Toolbar } from '@material-ui/core';


const FIND_ITEMS = gql`
    query GetItems($path : String, $query : String, $limit : Int, $after : String, $before : String) {
        items(path : $path, query: $query, limit : $limit, after : $after, before : $before) {
            totalCount
            edges {
                node {
                    id
                    path
                    key
                    title
                    description
                    folder
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;



const useStyles = makeStyles(theme => ({
        paper : {
            [theme.breakpoints.up('md')]: {
                minWidth : '480px'
            }
        }
    })
);

const Secondary = ({ item }) => {
    return (
        <div>
            { item.path }{ item.key }<br />
            { item.description }
        </div>
    )
}


const ItemPicker = ({ open, title, path, onClose }) => {
    const classes = useStyles();

    const [query, setQuery] = useState('');
    const [selected, setSelected] = useState();

    const [pagination, setPagination] = useState(null)

    const { loading, error, data, previousData } = useQuery(FIND_ITEMS, { variables : { path, query, limit : 10, ...pagination } });
    const { items } = (data || previousData) || { items : null }
    const { edges, pageInfo } = items || { edges : null, pageInfo : null }
    const { hasNextPage, hasPreviousPage, startCursor, endCursor } = pageInfo || { hasNextPage : false, hasPreviousPage : false, startCursor : '', endCursor : '' };

    const handleNext = async () => {
        if (hasNextPage)
            setPagination({ after : endCursor })
    }

    const handlePrev = () => {
        if (hasPreviousPage)
            setPagination({ before : startCursor })
    }

    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={open}  onClose={ () => onClose(false) } fullScreen={fullScreen} classes={{ paper: classes.paper }}>
            <DialogTitle color="primary" id="form-dialog-title">{ title || 'Find item' }</DialogTitle>
            <DialogContent>

                <TextField  size="small"  value={ query } onChange={ e => setQuery(e.target.value) } fullWidth label="Search" autoFocus />
                { loading && <LinearProgress /> }

                <List dense>
                    { edges && edges.map((edge) => (
                        <ListItem key={ edge.node.id } divider selected={ selected === edge.node} onClick={ () => setSelected(edge.node) }>
                            <ListItemText primary={ edge.node.title } secondary={ <Secondary item={ edge.node } /> } />
                        </ListItem>
                    ))}

                </List>

                <Toolbar>
                    <div style={{ flexGrow : 1 }}></div>
                    <IconButton onClick={ handlePrev } disabled={ !hasPreviousPage }>
                        <ChevronLeftIcon/>
                    </IconButton>
                    <IconButton onClick={ handleNext } disabled={ !hasNextPage }>
                        <ChevronRightIcon/>
                    </IconButton>
                </Toolbar>
            </DialogContent>
            <DialogActions>
                <Button onClick={ () => onClose(false) } color="primary">Cancel</Button>
                <Button onClick={ () => onClose(selected) } disabled={ !selected} color="primary">Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ItemPicker;