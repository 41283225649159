import React from 'react';

// Material UI

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components

import Page from '../templates/Page'


const useStyles = makeStyles((theme) => ({
   
}));

export default function SignIn() {
  const classes = useStyles();
  
  return (
    <Page title="Home">
        <div style={{ width : '100%', height : '100%', display: 'flex', alignItems : 'center', justifyContent : 'center '}}>
          <img src="welcome.svg" width="320"/>
        </div>
    </Page>
  );
}