import { useEffect, useState } from "react";


const useSelectionList = () => {
    const [selection, setSelection] = useState([]);

    useEffect(() => {
        setSelection([])
    }, []);


    const select = (item) => {
        if (selection.indexOf(item) < 0) {
            setSelection([ ...selection, item ]);
        }
    }


    const unselect = (item) => {
        const index = selection.indexOf(item);
        if (index >= 0) {
            setSelection([ 
                ...selection.slice(0, index), 
                ...selection.slice(index + 1)
            ]);
        }
    }

    const toggle = (item) => {
        
        
        if (selection.indexOf(item) >= 0) {
            unselect(item);
        }
        else {
            select(item);
        }
    }

    
    const clear = () => {
        
        setSelection([]);
    }



    return {
        selection,
        select,
        unselect,
        toggle,
        clear
    }
    
}


export default useSelectionList;