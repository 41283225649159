import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

// Material UI

import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

// Material UI Icons

import RefreshIcon from '@material-ui/icons/Refresh';
import FolderIcon from '@material-ui/icons/Folder';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

// Ducks 


import { ListItemIcon } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { parsePath } from '../utils';
import useQueryParams from '../hooks/useQueryParams';

import { qs } from '../utils'


// useStyles

const useStyles = makeStyles(theme => ({
        spinnerCell : {
            position: 'relative',
        },
        progress : {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0
        },
        search : {
            flexGrow: 1,
            borderRadius: 0
        }

    })
);


const Title = ({ path }) => {

    const parts = [
        { path : '/', text : 'root' },
        ...(path ||'').split('/').filter(p => p.length > 0).map((item, index, self) => {
        
            var p = self.slice(0, index + 1).join('/');
    
            return {
                path : p.length > 0 ? p : '/',
                text : item.length > 0 ? item : 'root'
            }
        })
    ]
    

    return (
        <Breadcrumbs style={{ flexGrow : 1}}>
            {
                parts.map((p, index) => {
                    return (
                        <Link component={ RouterLink } key={ p.path } to={ p.path != '/' ? `/mapping/items?path=${ p.path }&current=${ p.path }` : `/mapping/items` }>{ p.text }</Link>
                    )
                })
            }
            
        </Breadcrumbs>
    )

}


const ItemTree = ({ after, before, query, path, items, startCursor, endCursor, showPrevious, showNext, onChangeQuery, onRefresh }) => {
    const classes = useStyles();

    
    let isBusy = false;
    let { path : parent  }= parsePath(path);

    const queryParams = useQueryParams();


    const handleRefresh = () => {
            
        onRefresh();
    }

let folder = true;

    const { after : _, before : __, ...rest } = queryParams;
    const next = `/mapping/items?${ qs({ ...rest, after : endCursor }) }`;
    const prev = `/mapping/items?${ qs({ ...rest, before : startCursor }) }`;

    const getLink = (item) => {
        if (item.folder) {
            return `/mapping/items?${ qs({ path : item.path + item.key, current : item.path + item.key }) }`
        }
        else {
            return `/mapping/items?${ qs({ ...queryParams, current : item.path + item.key }) }`
        }
        
    }

    return (
        <Paper>
            <Toolbar className={ classes.toolbar }>
                {
                    folder && path != '/' &&
                    <IconButton edge="start" component={ RouterLink } className={ classes.back } to={ `/mapping/items?path=${ parent }` } onClick={ e => e.stopPropagation() }>
                        <ArrowBackIcon/>
                    </IconButton>
                }

                <Title path={ path } />

                <IconButton edge="end" color="inherit" onClick={ handleRefresh } >
                    <RefreshIcon></RefreshIcon>
                </IconButton>
            </Toolbar>

                <Box px={ 2 }>
                    <TextField value={ query } size="small" label="Search" fullWidth onChange={ onChangeQuery } />
                    { isBusy && <LinearProgress /> }
                </Box>

                <List>
                    { 
                    items.map((item) => (
                        <ListItem button key={ item.path + item.key } hover={ true } component={ RouterLink } to={ getLink(item) }>
                            <ListItemIcon edge="start">
                                { item.folder ? <FolderIcon color="disabled" style={{ marginRight: '10px' }}/> : <InsertDriveFileIcon  color="disabled" style={{ marginRight: '10px' }} /> }
                            </ListItemIcon>

                            <ListItemText primary={ item.title } />
                            </ListItem>
                        ))}
                </List>
            <Toolbar>
                <Button component={ RouterLink } to={ prev } disabled={ !showPrevious }>Prev</Button>
                <Button component={ RouterLink } to={ next } disabled={ !showNext }>Next</Button>
            </Toolbar>
        </Paper>
    )
}

export default ItemTree;
