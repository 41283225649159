
export const normalizePath = (path) => {
    let nPath = (path || '').trim();
    if (nPath[0] != '/')
        nPath = '/' + nPath;

    if (nPath[nPath.length - 1] != '/')
        nPath = nPath + '/';

    return nPath;
}

export const parsePath = (path) => {
    path = (path ||'').trim();

    const pathParts = path.replace(/^\/|\/$/g, '').split('/');
	const key = pathParts[pathParts.length - 1].trim();
	const nPath = '/' + pathParts.slice(0, pathParts.length - 1).join('/') ;
    const pPath = pathParts.length > 2 ? '/' + pathParts.slice(0, pathParts.length - 2).join('/') : null;

    return { 
        parts : pathParts,
        parent : pPath,
        path : nPath, 
        key
    };
}




export const qs = (o) => Object.keys(o).map(k => `${ encodeURIComponent(k) }=${ encodeURIComponent(o[k])}`).join('&');

