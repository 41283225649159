import { Switch, Route  } from "react-router-dom";


import LogStreamEvents from './pages/LogStreamEvents'
import LogGroup from './pages/LogGroup'
import LogGroups from './pages/LogGroups'

function App() {
	return (
		<Switch>
            <Route path="/logs/log-events" component={ LogStreamEvents } />
            <Route path="/logs/log-group" component={ LogGroup } />
            <Route path="/logs" component={ LogGroups } />
		</Switch>
	);
}

export default App;
