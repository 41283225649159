import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { API } from 'aws-amplify'
import { Link as RouterLink } from 'react-router-dom'
import { useMutation } from '@apollo/client';

// Material UI
import { makeStyles } from  '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import AppBar from '@material-ui/core/AppBar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';

// Material Icons

import LinkIcon from '@material-ui/icons/Link'
import DeleteIcon from '@material-ui/icons/Delete'
import CreateIcon from '@material-ui/icons/Create';


// Hooks

import useSelectionList from '../../../hooks/useSelectionList'

// Components

import CreateLinkDialog from '../dialogs/CreateLinkDialog'
import ConfirmationDialog from '../dialogs/ConfirmationDialog';

// Ducks 

import { IconButton } from '@material-ui/core';
import { parsePath } from '../utils';
import ItemPicker from '../dialogs/ItemPicker';
import ItemIdentity from '../components/ItemIdentity';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { useQuery, gql } from '@apollo/client';
import useQueryParams from '../hooks/useQueryParams';
import { qs} from '../utils';
import { DELETE_LINK, ADD_LINK } from '../queries';


const GET_ITEM = gql`
    query GetItemDetails($path: String!) {
        item(path: $path) {
            id
            path
            key
            title
            description
            links {
                id,
                target {
                    path,
                    key,
                    title
                },
                created
            }
        }
    }
`;

const DELETE_ITEM = gql`
    mutation DeleteItem($id: ID!) {
        deleteItem(id: $id) {
            id
        }
    }
`;



const useStyles = makeStyles((theme) => ({
  	title: {
    	display: 'flex'
  	},
    scope : { textTransform : 'uppercase' },
    root: {
        display: 'flex',
      },
      titleRoot : {
          flexGrow : 1,
      },
      title : {
          display: 'flex',
          alignItems: 'center',
          fontWeight : 'normal'
      },
      scope : {
          textTransform : 'uppercase'
      },
      details: {
        display: 'flex',
        flexDirection: 'column',
      },
      content: {
        flex: '1 0 auto',
      },
}));




const Title = ({ item }) => {
    const classes = useStyles();

    if (!item)
        return null;


    let root = item ? item.path.split('/')[1] : '(Root)';
    if (!root)
        root = '(Root)'

    return (
        <div className={ classes.titleRoot }>
            {
                item ?
                (
                    <Typography variant="h6" className={ classes.title }>{ item && item.title }</Typography>
                ) : (
                    <Typography variant="h6" className={ classes.title } color="textSecondary">(No item selected)</Typography>
                )
            }
            
            <Typography variant="body2" color="textSecondary">
                { item && `${ item.path }${ item.key }` }
            </Typography>
            
            <Typography variant="body2" color="textSecondary">{ item && item.description }</Typography>
        </div>
    )
}






const ItemDetails = ({ path, onDeleted }) => {

    console.log('CURRENT', path);


    const { loading, error, data, refetch } = useQuery(GET_ITEM, { variables : { path }, skip : !path });
    const [deleteLink] = useMutation(DELETE_LINK);
    const [createLink] = useMutation(ADD_LINK);
    

    useEffect(() => {
        refetch();
    }, [path])

    console.log('CURRENTXZ', path, data, loading, error);


    const pPath = parsePath(path);

    const { data : parentData } = useQuery(GET_ITEM, { variables : { path : pPath.path } });

    const { item } = data || { item : null };
    const { links } = item || { links: [] }
    
    const parentLinks = parentData && parentData.item && parentData.item.links.map(p => p.target) ||[];
    


    const [ showCreateManualLink, setShowCreateManualLink ] = useState(false);
    const [ showConfirmDelete, setShowConfirmDelete ] = useState(false);
    const [ showConfirmDeleteItem, setShowConfirmDeleteItem ] = useState(false);
    const { selection, toggle, clear } = useSelectionList();
    const [quicklink, setQuicklink ] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);


    const [deleteItem] = useMutation(DELETE_ITEM);

    const handleClick = async (event) => {
        if (parentLinks.length > 1) {
            setAnchorEl(event.currentTarget);
        }
        else {
            setQuicklink(parentLinks[0].path + parentLinks[0].key);
        }
      
    };
  
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const selectQuicklinkSource = (value) => {
        setAnchorEl(null);
        setQuicklink(value);
    }
  
    const handleQuicklinkClose = async (value) => {
        if (value) {
            await createLink({ variables : { source : parseInt(item.id), target : parseInt(value.id) } });
            refetch();
        }
        setQuicklink(null);
    }

    const handleNewLinkClose = (value) => {
        if (value) {
            refetch();
        }
        setShowCreateManualLink(false)
    }

    const handleConfirmRemove = async () => {
        setShowConfirmDelete(false);
        
            const promises = selection.map(s => deleteLink({ variables : { id : s } }));

            await Promise.all(promises);
            await refetch();
            clear();

        
    }



    

    const handleDeleteItem = () => {
        setShowConfirmDeleteItem(true);
    }

    const handleConfirmDeleteItem = async () => {
        try {
            await deleteItem({ variables : { id : item.id } });
            setShowConfirmDeleteItem(false);
            onDeleted();
        }
        catch(e) {
            setShowConfirmDeleteItem(false);
            if (e.graphQLErrors) {
                e.graphQLErrors.forEach(ge => {
                    if (ge.extensions.code === 'BAD_USER_INPUT') {
                        alert(ge.message);
                    }
                    
                });
            }
            
            
        }
    }

    const handleEdit = () => {
     //   alert('Not implemented')
    }


    const gotoLink = (item) => `/mapping/items?${ qs({ path : item.path, current : item.path + item.key }) }`;

    if(loading)
        return null;

    return (
        <Paper>

            <Toolbar>
                <Title item={ item } />

                <IconButton  disabled={ !item } onClick={ handleEdit}>
                    <CreateIcon />
                </IconButton>
                <IconButton  edge="end" disabled={ !item } onClick={ handleDeleteItem }>
                    <DeleteIcon />
                </IconButton>
            </Toolbar>

            <ConfirmationDialog open={ showConfirmDeleteItem } title="Delete item?" text="Are you sure you wish to delete this item?" onCancel={ () => setShowConfirmDeleteItem(false) } onConfirm={ handleConfirmDeleteItem }/>


            {
                selection.length > 0 &&
                
                <AppBar position="relative" elevation={ 0 } color="secondary">
                    <Toolbar>
                        <Typography variant="body2" style={{ flexGrow : 1 }}>{ selection.length } item(s) selected</Typography>
                        
                        <IconButton edge="end" color="inherit" onClick={ () => setShowConfirmDelete(true) }>
                            <DeleteIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            }

            {
                selection.length == 0 &&
                    <Toolbar>
                        <Typography variant="h6" style={{ flexGrow : 1 }}>Links</Typography>

                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={ handleClick } disabled={ parentLinks.length == 0}>
                            { parentLinks.length > 1 ? 'Quick Link...' : 'Quick Link' }
                        </Button>

                        <Button startIcon={ <LinkIcon />} disabled={ !item} onClick={ () => setShowCreateManualLink(true)}>Link Manually</Button>
                    </Toolbar>
            }

            <List>
                { links.map((link) => (

                    <ListItem key={ link.id }>
                         <ListItemIcon>
                            <Checkbox edge="start" disableRipple checked={ selection.indexOf(link.id) >= 0 } onChange={ () => toggle(link.id) } />
                        </ListItemIcon>

                        <ListItemText primary={ <ItemIdentity item={ link.target } />}>
                        </ListItemText>


                        <ListItemIcon>
                            <Button component={ RouterLink } to={ gotoLink(link.target) }>GO TO</Button>
                        </ListItemIcon>
                    </ListItem>
                ))}
            </List>

            <CreateLinkDialog title="Create link manually" item={ item } open={ showCreateManualLink } onClose={ handleNewLinkClose } />
            <ConfirmationDialog open={ showConfirmDelete } title="Delete these links?" text={ `Are you sure you wish to delete these ${ selection.length } links(s)?` }
                    onCancel={ () => setShowConfirmDelete(false) }
                    onConfirm={ handleConfirmRemove } />

            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu } >
                {
                    parentLinks.map(l => {
                        return (
                            <MenuItem onClick={ e => selectQuicklinkSource(l.path + l.key)}>To items in "{ l.path + l.key }"</MenuItem>
                        )
                    })
                }
            </Menu>

            <ItemPicker open={ Boolean(quicklink) } path={ quicklink } title={ `Find match for ${ item && item.title }`} onClose={ handleQuicklinkClose }  />

            </Paper>
    )
}

export default ItemDetails;
