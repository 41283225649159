import React from 'react';
import clsx from 'clsx'
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Auth } from 'aws-amplify'
import PageMenu from './PageMenu'
import { useDispatch, useSelector } from 'react-redux';

import { toggleMenu } from '../ducks/ui';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		inset: 0,
		display: 'flex',
		flexDirection: 'column'
  	},
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        
        
      },
      appBarOpen : {
      },
      
	content: {
		flex: 1,
		position: 'relative'
  	},
  	menuButton: {
    	marginRight: theme.spacing(2),
  	},
  	title: {
    	flexGrow: 1,
  	},
}));


const PageHeader = ({ title, back }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const doShowMenu = useSelector(state => state.ui.showMenu);

    const history = useHistory();

  
    
      const appBarClasses = clsx(classes.appBar, 
        {
          [classes.appBarOpen] :doShowMenu
      })

    return (
            <AppBar position="static" className={appBarClasses} color="primary" elevation={ 0 }>
                <Toolbar>
                    {
                        back  &&
                        <IconButton edge="start" onClick={ () => history.goBack() }>
                            <ArrowBack />
                        </IconButton>
                    }

                    <Typography variant="h6" className={classes.title}>
                        { title }
                    </Typography>
                </Toolbar>
            </AppBar>
    )
}
export default PageHeader;