import { useState} from 'react'
import { useParams, useLocation } from 'react-router-dom'

// Material UI

import { makeStyles } from '@material-ui/core/styles';

// Templates

import Page from '../../templates/Page'
import { useEffect, useRef } from 'react';

const useStyles = makeStyles(() => ({
    container: {
		width: '100%',
        height: '100%'
  	},
	iframe: {
		width: '100%',
        height: '100%'
  	}
}));


const App = () => {
    
    const classes = useStyles();

    const params = useParams();
    const location = useLocation();
    const splat = params[0];
    const ref = useRef();
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        if (ref.current) {
            setBusy(true);

            const iframe = document.createElement('iframe');
            iframe.className = classes.iframe;
            iframe.frameBorder = "0";
            iframe.onload = () => { setBusy(false); }
            iframe.src =  `${ process.env.REACT_APP_SPORTSBET_LEGACY_BASE_URL }/${ splat }?naked=true${ location.hash }` 
            ref.current.appendChild(iframe);

            return () => {
                if (ref.current) {
                    ref.current.removeChild(iframe);
                }
            }
        }
    }, [ref, location, splat]);
    
    
    return (
        <Page busy={ busy } title="Daily Fantasy" fullHeight>
            <div className={ classes.container}  ref={ ref }>

            </div>
            
        </Page>
    )
}

export default App;
