import { useState, useEffect } from 'react'

import { Link, useParams } from 'react-router-dom'

import { CloudWatchLogsClient, GetLogEventsCommand } from "@aws-sdk/client-cloudwatch-logs";

import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { Auth, Hub, Logger } from 'aws-amplify';


import Page from "../../../templates/Page";
import { Container, Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import useQueryParams from '../../mapping/hooks/useQueryParams';



const LogGroup = () => {
    const { logGroupName, logStreamName } = useQueryParams();
    const [result, setResult] = useState(null)


	useEffect(async () => {
		const session = await Auth.currentSession();

		const cognitoIdentityClient = new CognitoIdentityClient({ region: "eu-west-1" });
		const client = new CloudWatchLogsClient({ 
				region: "eu-west-1",
				credentials: fromCognitoIdentityPool({
				  client: cognitoIdentityClient,
				  identityPoolId: 'eu-west-1:b3882853-0f5a-49df-9552-0a61246c18b5',
					  logins : {
						'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_ibiNR8TIf' : session.getIdToken().getJwtToken()
					  }
				})
			  });
			
			  
					const cmd = new GetLogEventsCommand({
                        logGroupName, logStreamName
					})
					
					
        const res = await client.send(cmd);
        setResult(res);


	}, [])

    return (
        <Page title="Log Group">
            <h1>LOGGORUP</h1>
                <Container>
                    <TableContainer component={ Paper }>
                    <Table>
                {
                    result && result.events.map(r => {
                        return (
                            <TableRow>
                                <TableCell>
                                    { r.timestamp}
                                </TableCell>
                                <TableCell>
                                    <pre>
                                        
                                
                                        {r.message}
                                        </pre>
                                   
                                </TableCell>
                                
                            </TableRow>
                        )
                    })
                }
                </Table>
                </TableContainer>
                </Container>
        </Page>
    )
}

export default LogGroup;