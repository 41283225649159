import { useEffect } from "react";
import { Switch, Route, Redirect  } from "react-router-dom";
import { AmplifyAuthenticator, AmplifySignOut, AmplifySignIn } from '@aws-amplify/ui-react';

import Home from './pages/Home'

import Logs from './modules/logs/App'
import Import from './modules/import/App'
import Legacy from './modules/legacy/App'
import Sportsbet from './modules/sportsbet/App'
import SAM from './modules/sam/App'
import Mapping from './modules/mapping/App'
import Alerts from './modules/alerts/App'

import useAuthentication from "./hooks/useAuthentication";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import ErrorHandler from './containers/ErrorHandler';




const theme = createMuiTheme({
	palette : {
		status: {
			danger: '#ff4444',
			dangerDark: '#fCC0000',
			warning: '#ffbb33',
			warningDark: '#FF8800',
			success: '#00C851',
			successDark: '#007E33',
			info: '#33b5e5',
			infoDark: '#0099CC'
		}
	}
});






function App() {
	const authentication = useAuthentication();




	if (!authentication.ready) {
		return null;
	}
	else {
		return (
			<AmplifyAuthenticator>
				<AmplifySignIn slot="sign-in" 
					usernameAlias="email"
					formFields={[
						{
							type: "email",
							
							placeholder: "e-mail address",
							required: true,
						},
						{
							type: "password",
							placeholder: "password",
							required: true,
						}
					]} 
					hideSignUp 
				/>
				<ThemeProvider theme={ theme }>
					
						<Switch>
							
							<Route path="/import" component={ Import } />
							
							<Route path={ [ '/sam/*', '/sam' ] } component={ SAM } />
							<Route path={ [ '/season/*', '/season' ] } component={ Legacy } />
							<Route path={ [ '/sportsbet/*', '/sportsbet' ] } component={ Sportsbet } />
							<Route path="/mapping" component={ Mapping  } />
							<Route path="/logs" component={ Logs  } />
							<Route path="/alerts" component={ Alerts  } />
							<Route path="/" component={ Home } />
						</Switch>

					<ErrorHandler />
					
				</ThemeProvider>
			</AmplifyAuthenticator>
      	);
	}
}

export default App;
