import { gql } from '@apollo/client';

export const GET_ITEM = gql`
query GetItem($path: String!) {
    item(path: $path) {
        id
        path
        key
        title
        description
    }
}
`;

export const ADD_ITEM = gql`
    mutation AddItem($path: String!, $key: String!, $title: String!, $folder: Boolean!) {
        createItem(path: $path, key: $key, title: $title, folder: $folder) {
              id
        }
    }
`


export const ADD_LINK = gql`
    mutation AddLink($source: Int!, $target: Int!) {
        createLink(source: $source, target: $target) {
              id
        }
    }
`


export const DELETE_LINK = gql`
    mutation DeleteLink($id: ID!) {
        deleteLink(id: $id) {
            id
        }
    }
`
