import { Link as RouterLink } from 'react-router-dom'


import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

// Global Components

import Page from "../../../templates/Page";


const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    footer : {
        textAlign: 'right'
    }
}));


const Dashboard = () => {
    const classes = useStyles();

    return (
        <Page title="Dashboard">
            <Container>
                <Paper className={ classes.paper }>
                    <Typography variant="h6">Item catalog</Typography>
                    <Typography variant="body1" component="p" color="textSecondary">
                        Find items in the mapping catalog.
                    </Typography>
                    <footer className={ classes.footer }>
                        <Button variant="contained" color="primary" component={ RouterLink } to={ '/mapping/items' }>View item catalog</Button>
                    </footer>
                </Paper>
            </Container>
        </Page>
    )
    
}

export default Dashboard;