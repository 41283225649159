import React, { useState } from 'react'
import { useQuery } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom'
import dayjs from 'dayjs'

// Material UI

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey';

// Material Icons


// Components

import Page from '../../../templates/Page'

// Hooks 

import { GET_ALERT } from '../queries'
import { useHistory, useParams } from 'react-router';
import { Divider, IconButton,  Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Title = ({ title }) => {
    const history = useHistory();

    return (
        <>
            <IconButton edge="start" onClick={ () => history.goBack() } color="inherit">
                <ArrowBackIcon />
            </IconButton>
            { title }
        </>
    )
}


const Alert = () => {
    const { id } = useParams();
    const history = useHistory();


    const [after, setAfter] = useState(null);
    const [before, setBefore] = useState(null);

    const { loading, data  } = useQuery(GET_ALERT, { variables : { id, after, before } });
    
    const instance = data && data.alert.instances.edges.length > 0 && data.alert.instances.edges[0].node;
    const details = instance ? JSON.parse(instance.details) : null;
    const detailsText = details ? JSON.stringify(details) : null;
    const instancesPageInfo = data && data.alert.instances.pageInfo;
    const hasNextPage = instancesPageInfo && instancesPageInfo.hasNextPage;
    const hasPreviousPage = instancesPageInfo && instancesPageInfo.hasPreviousPage;

    const handleNextPage = () => {
        setBefore(null);
        setAfter(instancesPageInfo.endCursor);
    }

    const handlePreviousPage = () => {
        setAfter(null);
        setBefore(instancesPageInfo.startCursor);
    }
    
    const getActionUrl = (action) => {
        if (action.type == 'MAP') {
            const { path, key, title, description } = action.parameters;
            
            return `/mapping/lookup?path=${ encodeURIComponent(path) }&key=${ encodeURIComponent(key) }&title=${ encodeURIComponent(title) }&description=${ encodeURIComponent(description || '') }`
            
        }
        else if (action.type == 'LINK') {
            const { url } = action.parameters;
            return url;
        }
        else {
            return null;
        }

    }


    if (!data)
        return <Page title={ <Title title="View alert" /> }  busy={ loading }></Page>

    return (
        <Page title={ <Title title="View alert" /> }  busy={ loading }>
            <Container>
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 8 }>
                        
                        

                        <Paper style={{ position : 'relative', marginBottom: '12px' }}>
                            <Box p={2 }>
                            <Toolbar>
                                <Typography variant="h6" style={{ flexGrow : 1 }}>{ data.alert.title }</Typography>
                            </Toolbar>
                            </Box>
                        </Paper>

                        <Paper style={{ position : 'relative' }}>


                            <Toolbar>
                            <Typography variant="h6" style={{ flexGrow : 1 }}>Instance #{ instance && instance.id }</Typography>
                            <Typography variant="body2" color="textSecondary">{ instance && dayjs(instance.created).format('DD MMM. YYYY, HH:mm') }</Typography>
                            <IconButton disabled={ !hasPreviousPage } onClick={ handlePreviousPage}>
                                <ChevronLeftIcon></ChevronLeftIcon>
                            </IconButton>
                            <IconButton disabled={ !hasNextPage } onClick={ handleNextPage}>
                                <ChevronRightIcon></ChevronRightIcon>
                            </IconButton>
                        </Toolbar>


                        <Box p={2 }>
                                <Typography variant="subtitle1">Message</Typography>

                                <pre style={{ background: grey[200], padding: '12px', whiteSpace: 'pre-wrap', wordWrap: 'break-word'  }}>
                                    { instance ? instance.message : 'No message available' }
                                </pre>


                                <Typography variant="subtitle1">Details</Typography>

                                <pre style={{ background: grey[200], padding: '12px', whiteSpace: 'pre-wrap', wordWrap: 'break-word'  }}>
                                    { detailsText ? detailsText : 'No details available' }
                                </pre>


                            </Box>

                        
                        </Paper>
                    </Grid>
                    <Grid item xs={ 3 }>
                        <Box pb={ 2 }>
                            <Typography variant="subtitle1" color="textSecondary">Instance count</Typography>
                            <Typography variant="h2">{ data.alert.instanceCount }</Typography>
                        </Box>

                        <Divider />

                        <Box py={ 2 }>
                            <Typography variant="subtitle1" color="textSecondary">Oldest</Typography>
                            <Typography variant="body1">{ dayjs(data.alert.created).format('DD MMM. YYYY, HH:mm') }</Typography>

                            <Typography variant="subtitle1" color="textSecondary">Latest</Typography>
                            <Typography variant="body1">{ dayjs(data.alert.lastSeen).format('DD MMM. YYYY, HH:mm') }</Typography>
                        </Box>

                        

                        
                        {

                                details && details.actions &&
                                <>
                                    <Divider />
                                        
                                        <Box py={ 2 }> 
                                            
                                            <Typography variant="subtitle1" color="textSecondary">Instance actions</Typography>
                                            <List>
                                        {
                                            
                                                details.actions.map(a => {
                                                    return (
                                                        <ListItem component={ RouterLink } to={ getActionUrl(a) } button>
                                                            { a.name }
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </List>
                                    </Box>
                                </>
                                
                            }


                    </Grid>
                </Grid>

             
            </Container>


        </Page>
    )
}

export default Alert;
