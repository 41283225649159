import { Switch, Route  } from "react-router-dom";

import Dashboard from './pages/Dashboard'
import Items from './pages/Items'
import Lookup from './pages/Lookup'

function App() {
	return (
		<Switch>
			<Route path="/mapping/lookup" component={ Lookup } />
            <Route path="/mapping/items" component={ Items } />
            <Route path="/mapping" component={ Dashboard } />
		</Switch>
	);
}

export default App;
