import { makeStyles, useTheme } from '@material-ui/core/styles';


import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
    root : {
        display: 'flex',
        alignItems : 'center'

    },
    icon : {
        marginRight: theme.spacing(1)
    }
  
}))



const Icon = ({ type }) => {
    const theme = useTheme();

    switch (type) {
        case 'ERROR':
            return <ErrorIcon style={{ color: theme.palette.status.danger }} />
        case 'WARNING':
            return <WarningIcon style={{ color: theme.palette.status.warning }} />
        case 'INFO':
            return <InfoIcon style={{ color: theme.palette.status.info }}/>
    }
}


const AlertType = ({ type }) => {
    const classes = useStyles();

    if (!type)
        return null;

    return (
        <div className={ classes.root }>
            <div className={ classes.icon }>
                <Icon type={ type } />
            </div> 
            { type }
        </div>
    )


}

export default AlertType;