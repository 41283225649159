// Constants

import { createSelector } from "reselect";

const SHOW_MENU             = 'UI/SHOW_MENU';
const TOGGLE_MENU           = 'UI/TOGGLE_MENU';
const HIDE_MENU             = 'UI/HIDE_MENU';

const ADD_GENERAL_ERROR     = 'UI/ADD_GENERAL_ERROR';
const DISMISS_GENERAL_ERROR   = 'UI/DISMISS_GENERAL_ERROR';

/* Fetch User Competition Leaderboards */

export const showMenu = () => ({ type: SHOW_MENU });
export const toggleMenu = () => ({ type: TOGGLE_MENU });
export const hideMenu = () => ({ type: HIDE_MENU });


export const addGeneralError = (error) => ({ type: ADD_GENERAL_ERROR, error });
export const dismissGeneralError = () => ({ type: DISMISS_GENERAL_ERROR });


// Reducer

const initialState = {
    showMenu : false,
    errors : []
}


export default function reducer (state = initialState, action) 
{
    const { error } = action;

    switch (action.type) {
        case SHOW_MENU:
            return { ...state, showMenu : true };
        case TOGGLE_MENU:
            return { ...state, showMenu : !state.showMenu };
        case HIDE_MENU:
            return { ...state, showMenu : false };
        case ADD_GENERAL_ERROR:
            return { 
                ...state, 
                errors : [...state.errors, action.error]
            };
        case DISMISS_GENERAL_ERROR:
            return { 
                ...state, 
                errors : state.errors.slice(0, state.errors.length - 1) 
            };
        default:

               return state
            
    }
}


export const getGeneralErrors = createSelector(
    state => state.ui.errors,
    (errors) => errors
);