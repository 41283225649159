import { useEffect, useState, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router'

import { Auth, Hub } from 'aws-amplify';

const useAuthentication = () => {
    const [ready, setReady] = useState(false);
    const [authenticatedUser, setAuthenticatedUser] = useState(null);
    const history = useHistory();
    
    const authListener = useCallback(async (data) => {
        
        switch (data.payload.event) {
          case 'signIn':
                const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
                setAuthenticatedUser(!!currentAuthenticatedUser)
                history.push('/');
              break;
          case 'signUp':
              break;
          case 'signOut':
            setAuthenticatedUser(null);
              break;
          case 'signIn_failure':
              break;
          case 'configured':
        }
    }, [history])
    
    useEffect(async () => {
        try {
            const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
            setAuthenticatedUser(!!currentAuthenticatedUser)
            setReady(true);
        }
        catch(err) {
            setAuthenticatedUser(null);
            setReady(true);
        }


        Hub.listen('auth', authListener);

          return () => {
            Hub.remove('auth', authListener);
          }

    }, []);



    const response = useMemo(() => {
        return {
            ready,
            isAuthenticated : !!authenticatedUser,
            user : authenticatedUser
        }
    }, [ready, authenticatedUser])

    return response;
}

export default useAuthentication;