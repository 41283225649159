import { Typography } from "@material-ui/core";

const ItemIdentity = ({ item }) => {
    return (
        <div>
            { item.title }
            <Typography variant="body2" color="textSecondary">{ item.path + item.key }</Typography>
        </div>
    )
}

export default ItemIdentity;