import React, { useEffect, useState } from 'react'
import { useQuery, useMutation,useApolloClient } from '@apollo/client';
import { Link as RouterLink, useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

// Material UI

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from '@material-ui/core/Toolbar';
import TableHead from '@material-ui/core/TableHead';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';

// Material Icons

import DeleteIcon from '@material-ui/icons/Delete';
import ScheduleIcon from '@material-ui/icons/Schedule';
import FilterListIcon from '@material-ui/icons/FilterList';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';

// Components

import Page from '../../../templates/Page'
import AlertType from '../components/AlertType'
import AlertStatus from '../components/AlertStatus'

// Hooks 

import useQueryParams from '../../../hooks/useQueryParams';


import { GET_ALERTS, RESOLVE_ALERT, REOPEN_ALERT, DISMISS_ALERT, IGNORE_ALERT} from '../queries'
import useSelectionList from '../../../hooks/useSelectionList';
import { AppBar, IconButton, TableContainer, Typography, useTheme } from '@material-ui/core';

import { qs } from '../utils'




const Alerts = () => {
    const theme = useTheme();
    const history = useHistory();
    
    const queryParams =  useQueryParams();
    const { limit = 10, status = 'NEW', after, before } = queryParams;
    const vars = { status, limit, after, before };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [statusAnchorEl, setStatusAnchorEl] = React.useState(null);
    const [resolveAlert] = useMutation(RESOLVE_ALERT, { refetchQueries: [{ query : GET_ALERTS, variables : vars }] });
    const [reopenAlert] = useMutation(REOPEN_ALERT, { refetchQueries: [{ query : GET_ALERTS, variables : vars }] });
    const [dismissAlert] = useMutation(DISMISS_ALERT, { refetchQueries: [{ query: GET_ALERTS, variables : vars }] });
    const [ignoreAlert] = useMutation(IGNORE_ALERT, { refetchQueries: [{ query: GET_ALERTS, variables : vars }] });

    const { loading, data, refetch } = useQuery(GET_ALERTS, { variables : vars });
    const { selection, toggle, clear : clearSelection } = useSelectionList();

    const count = data ? data.alerts.totalCount : 0;
    const alerts = data ? data.alerts.edges.map(e => e.node) : [];
    const hasPreviousPage = data ? data.alerts.pageInfo.hasPreviousPage : false;
    const hasNextPage = data ? data.alerts.pageInfo.hasNextPage : false;


    const handleIgnoreClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleCloseIgnoreMenu = () => {
        setAnchorEl(null);
    };

    const handleSelectStatusClick = (event) => {
        setStatusAnchorEl(event.currentTarget);
    };
    
    const handleCloseStatusMenu = () => {
        setStatusAnchorEl(null);
    };

    useEffect(() => {
        refetch();
    }, [status, limit, after, before])

    const handleSetStatus = (type) => {
        const url = `alerts?${ qs({ ...queryParams, before : null, after : null, status : type }) }`;
        history.push(url);
        setStatusAnchorEl(null);
    };


    const dispatchSelectionCommand = async (command, arg) => {
        setStatusAnchorEl(null);
        setAnchorEl(null);
        
        switch(command) {
            case 'RESOLVE':
                await Promise.all(selection.map(id => {
                    return resolveAlert({ variables : { id }})  
                }));
                break;
            case 'REOPEN':
                await Promise.all(selection.map(id => {
                    return reopenAlert({ variables : { id }})  
                }));
                break;
            case 'DISMISS':
                
                await Promise.all(selection.map(id => {
                    
                    return dismissAlert({ variables : { id, time : arg }})  
                }));
                break;
            case 'IGNORE':
                await Promise.all(selection.map(id => ignoreAlert({ variables : { id }}) ));
                break;
        }


        
        clearSelection();
    }



    const endCursor = data && data.alerts.pageInfo.endCursor;
    const startCursor = data && data.alerts.pageInfo.startCursor;
    const nextPage = `alerts?${ qs({ ...queryParams, before : null, after : endCursor }) }`;
    const prevPage = `alerts?${ qs({ ...queryParams, before : startCursor, after : null }) }`;
    


    return (
        <Page title="Alerts" busy={ loading }>
            <Container>
                <Paper style={{ position : 'relative' }}>
                    {
                        selection.length > 0 &&
                        <AppBar position="absolute" color="secondary" elevation={ 0 }>
                            <Toolbar>
                                <Typography style={{ flexGrow : 1 }} variant="body2">{ `${ selection.length } item(s) selected` }</Typography>
                                <IconButton color="inherit" onClick={ handleIgnoreClick }>
                                    <ChangeHistoryIcon/>
                                </IconButton>
                                <IconButton color="inherit">
                                    <DeleteIcon/>
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    }

                    <Toolbar>
                        
                        <Typography variant="h6" style={{ marginRight : theme.spacing(2) }}>Alerts</Typography>

                        <div style={{ flexGrow: 1 }}>
                            { status !== 'NEW' && <Chip label={ status }  onDelete={ () => handleSetStatus('NEW') }/> }
                        </div>

                        <IconButton color="inherit" onClick={ handleSelectStatusClick }>
                            <FilterListIcon/>
                        </IconButton>
                    </Toolbar>

                    <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                            <TableCell as="th" padding="checkbox"></TableCell>
               
                                <TableCell as="th">Group</TableCell>
                                <TableCell as="th">Title</TableCell>
                                
                                
                                <TableCell as="th">Instances</TableCell>
                                <TableCell as="th">Status</TableCell>
                                <TableCell as="th">Type</TableCell>

                                <TableCell as="th">Last Seen</TableCell>
                            </TableRow>
                        </TableHead>
                        {
                            alerts.map(a => {
                                return (
                                    <TableRow key={ a.id } hover>
                                        <TableCell   padding="checkbox">
                                            <Checkbox checked={ selection.indexOf(a.id) >= 0 } onChange={ () =>  toggle(a.id) } />
                                        </TableCell>
                                        <TableCell>
                                            { a.group || '(None)'}
                                        </TableCell>
                                        <TableCell>
                                            <Link component={ RouterLink } to={ `/alerts/${ a.id }` }>{ a.title }</Link>
                                        </TableCell>

                                        
                                        <TableCell>{ a.instanceCount }</TableCell>
                                        <TableCell><AlertStatus status={ a.status } /></TableCell>
                                        <TableCell><AlertType type={ a.type } /></TableCell>
                                       
                                        <TableCell style={{ whiteSpace: 'nowrap'}}>{ dayjs(a.lastSeen).format('DD MMM. YYYY, HH:mm') }</TableCell>
                                    </TableRow>
                                )
                            } )
                        }

                    </Table>
                    </TableContainer>

                    <Toolbar>
                        <div style={{ flexGrow : 1 }}></div>
                        <IconButton disabled={ !hasPreviousPage } component={ RouterLink } to={ prevPage }>
                            <ChevronLeftIcon/>
                        </IconButton>
                        <IconButton disabled={ !hasNextPage } component={ RouterLink } to={ nextPage }>
                            <ChevronRightIcon/>
                        </IconButton>
                    </Toolbar>

                    

                </Paper>
            </Container>


            <Menu anchorEl={ anchorEl } keepMounted open={Boolean(anchorEl)} onClose={handleCloseIgnoreMenu}>
                <MenuItem onClick={ () => dispatchSelectionCommand('REOPEN') }>Reopen</MenuItem>
                <MenuItem onClick={ () => dispatchSelectionCommand('RESOLVE') }>Resolve</MenuItem>
                <Divider />
                <MenuItem onClick={ () => dispatchSelectionCommand('DISMISS', 0) }>Dismiss</MenuItem>
                <MenuItem onClick={ () => dispatchSelectionCommand('DISMISS', 60 * 60) }>Dismiss for 1 hour</MenuItem>
                <MenuItem onClick={ () => dispatchSelectionCommand('DISMISS', 6 * 60 * 60) }>Dismiss for 6 hours</MenuItem>
                
                <MenuItem onClick={ () => dispatchSelectionCommand('DISMISS', 1 * 24 * 60 * 60)  }>Dismiss for 1 day</MenuItem>
                <MenuItem onClick={ () => dispatchSelectionCommand('DISMISS', 2 * 24 * 60 * 60)  }>Dismiss for 2 day</MenuItem>
                <MenuItem onClick={ () => dispatchSelectionCommand('DISMISS', 3 * 24 * 60 * 60)  }>Dismiss for 3 day</MenuItem>
                <MenuItem onClick={ () => dispatchSelectionCommand('DISMISS', 4 * 24 * 60 * 60)  }>Dismiss for 4 day</MenuItem>
                <MenuItem onClick={ () => dispatchSelectionCommand('DISMISS', 5 * 24 * 60 * 60)  }>Dismiss for 5 day</MenuItem>
                <MenuItem onClick={ () => dispatchSelectionCommand('DISMISS', 6 * 24 * 60 * 60)  }>Dismiss for 6 day</MenuItem>

                <MenuItem onClick={ () => dispatchSelectionCommand('DISMISS', 7 * 24 * 60 * 60)  }>Dismiss for 1 week</MenuItem>
                <MenuItem onClick={ () => dispatchSelectionCommand('DISMISS', 30 * 24 * 60 * 60) }>Dismiss for 30 days</MenuItem>
                <Divider />
                <MenuItem onClick={ () => dispatchSelectionCommand('IGNORE')  }>Ignore permanently</MenuItem>
            </Menu>


            <Menu anchorEl={statusAnchorEl} keepMounted open={Boolean(statusAnchorEl)} onClose={handleCloseStatusMenu}>
                <MenuItem onClick={ () => handleSetStatus('NEW') } >New</MenuItem>
                <MenuItem onClick={ () => handleSetStatus('DISMISSED') }>Dismissed</MenuItem>
                <MenuItem onClick={ () => handleSetStatus('RESOLVED') }>Resolved</MenuItem>
                <MenuItem onClick={ () => handleSetStatus('IGNORED') }>Ignored</MenuItem>
            </Menu>

        </Page>
    )
}

export default Alerts;
