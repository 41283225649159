import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify, { API, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { CssBaseline } from '@material-ui/core';

import { BrowserRouter as Router  } from "react-router-dom";

import { Provider } from 'react-redux';
import store from './store';

import { from, ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';
import { addGeneralError } from './ducks/ui'

//...awsconfig,

Amplify.configure({
  
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ibiNR8TIf",
    "aws_user_pools_web_client_id": "1ao1a09and5ccfdum3jal10o3t",
  Auth: {
      region : "eu-west-1",  
      identityPoolId: "eu-west-1:b3882853-0f5a-49df-9552-0a61246c18b5",  
      
      userPoolId: "eu-west-1_ibiNR8TIf",
      userPoolName: "management-console-userpool-prod",
      userPoolWebClientId: "1ao1a09and5ccfdum3jal10o3t",
      mandatorySignIn: true
  },
  API : {
    endpoints : [
      { 
        name : 'mapping', 
        endpoint : process.env.REACT_APP_API_BASE_URL,
        custom_header: async () => { 
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        }
      }
    ]
  }
});


const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
	  	graphQLErrors.forEach(({ message, locations, extensions, path }) =>{
			if (extensions && extensions.code === 'INTERNAL_SERVER_ERROR') {
				if (extensions.exception && extensions.exception.code == 'ECONNREFUSED')
				{
					store.dispatch(addGeneralError('The server refused the connection.'));	
				}
				else
					store.dispatch(addGeneralError('An error occurred while contacting the server.'));
			} 
	  		
			console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
		}
	  );
  
	if (networkError) {
		store.dispatch(addGeneralError('A network error occurred.'));
	}
});


const httpLink = createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT });
const authLink = setContext(async (_, { headers }) => {
	const token = (await Auth.currentSession()).getAccessToken().getJwtToken()

	return {
	  	headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
		}
	}
});
/*errorLink*/

const additiveLink = from([
	errorLink,
	authLink,
	httpLink
  ]);


const client = new ApolloClient({
	link: additiveLink,
	//uri: 'http://localhost:4000/',
	cache: new InMemoryCache()
});






ReactDOM.render(
  <>
    <CssBaseline />
    <ApolloProvider client={ client }>
    <Provider store={ store }>
      <Router>
        <App />
      </Router>
    </Provider>
    </ApolloProvider>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
