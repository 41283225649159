import React, { useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListSubheader from '@material-ui/core/ListSubheader';

import { useDispatch, useSelector } from 'react-redux';
import { hideMenu } from '../ducks/ui';


import sitemap from '../sitemap'
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { ListItemIcon, ListItemText, Typography } from '@material-ui/core';

import { flatten } from '../utils'




const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
	overflow: 'hidden'
  },
  fullList: {
    width: 'auto',
  },
  drawer : {
    width: 250
  },
  drawerOffset: theme.mixins.toolbar,
  moduleHeader : {
    width: 250
  },
  moduleImage : {
    marginRight : theme.spacing(1)
  }
}));


const ModuleHeader = ({ menu }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleShowMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={ classes.moduleHeader }>
      <List>
        <ListItem button onClick={ handleShowMenu }>
          <ListItemIcon>
            <img className={ classes.moduleImage } src={ menu.module && menu.module.image } width="32" height="32"/>
          </ListItemIcon>
          <ListItemText>{ menu.module && menu.module.name }</ListItemText>
          <ExpandMoreIcon/>
        </ListItem>
      </List>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={ handleClose}>
        {
          menu.modules.map(m => {
              return (
                <MenuItem key={ m.name } component={ Link } to={ m && m.path  }>
                  <ListItemIcon>
                    <img className={ classes.moduleImage } src={ m.image } width="32" height="32"/>
                  </ListItemIcon>
                  
                  { m.name}

                </MenuItem>
              )
          }

          )
        }
        
      </Menu>
    </div>
  )

}

export default function PageMenu() {
  	const classes = useStyles();
  	const showMenu = useSelector(state => state.ui.showMenu);
  	const dispatch = useDispatch();

  	const location = useLocation();

	const menu = useMemo(() => {
		const flat = flatten(sitemap.children, c => c.children ||[]);
		
		const modules = flat.filter(f => f.level === 1);

		const item = flat.find(m => location.pathname + location.hash == m.path);
		
		if (item) {
			const parentParts = item.parent ? item.parent.split('.') : [];
			const moduleId = parentParts[0] || item.id;
			const module = flat.find(f => f.id == moduleId);

			const groups = flat.filter(f => f.parent == moduleId).map(g => {
				return {
					...g,
					children : flat.filter(f => f.parent == g.id)
				}
			});


			return {
				module,
				modules,
				groups
			}

		}
		else {
			return {
				modules
			};
		}
	}, [sitemap, location])


  

  const handleHideMenu = () => {
      dispatch(hideMenu());
  }


  const list = () => (
    <div className={ clsx(classes.list) } role="presentation">
      
      { menu && menu.groups && menu.groups.map(g => {
		  if (g.path) {
			  return (
					<List>
						<ListItem button key={g.name} component={ Link } to={ g.path }>
							{ g.name }
						</ListItem>
					</List>
				)
		  }
		  else

        return (
			<React.Fragment key={ g.name }>
				<List>
					<ListSubheader>{ g.name }</ListSubheader>

					{
						(g.children || []).map(r => {
							return (
							<ListItem button key={r.path} component={ Link } to={  r.path }>
								{ r.name }
							</ListItem>
							)
						})
					}

				</List>
			<Divider />
			</React.Fragment>
        )
      })}
    
    </div>
  );

  return (
    <Drawer className={ classes.drawer }  variant="persistent" open={ showMenu }  onClose={ handleHideMenu }>
        <div className={classes.drawerOffset}>
        </div>

      	<ModuleHeader menu={ menu } />

        <Divider />

        { list('left') }
    </Drawer>
  );
}